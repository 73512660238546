import { template as template_692a1e455f3d4b89b0403e417ba6d31b } from "@ember/template-compiler";
const FKText = template_692a1e455f3d4b89b0403e417ba6d31b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
