import { template as template_1819cd74ee8244dc9d8f05e58128a447 } from "@ember/template-compiler";
const WelcomeHeader = template_1819cd74ee8244dc9d8f05e58128a447(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
